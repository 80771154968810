import { Outlet } from 'react-router-dom';
import { Header } from '~/app/Header';
import { Container } from '~/atoms/Layout';
import { SnackBarContainer } from '~/features/snackbar/SnackBarContainer';

export function MainLayout() {
  return (
    <>
      <Header />

      <Container>
        <Outlet />
        <SnackBarContainer />
      </Container>
    </>
  );
}

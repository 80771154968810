import { useIsAuthenticated } from '@azure/msal-react';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Permissions, WhoAmiResult } from '~/app/generated/api-client/v1';
import * as paths from '~/features/skills-matrix/skills-matrix-paths';
import { selectWhoAmILoading } from '~/features/auth/authSlice';
import { LoginButton, loginText } from '~/features/auth/LoginScreen';
import { AuthGuard } from '~/features/auth/WhoAmIGuard';

const NavigateRoleHomePage: FC<{ user: WhoAmiResult }> = ({ user }) => {
  // SM-52
  // if user has admin permissions, treat the skills page as the home page
  // otherwise send them to their profile page.
  //
  // this will probably go away in the future when we have more tools than just Skills Matrix
  return user.permissions.includes(Permissions.ReadSkillReports) ? (
    <Navigate to={paths.skills()} />
  ) : (
    <Navigate to={paths.profile({ slug: user.slug })} />
  );
};

export const HomePage: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const isLoggingIn = useSelector(selectWhoAmILoading);

  return (
    <>
      {!isAuthenticated && (
        <Stack alignItems="center" pt={4}>
          <Typography variant="h1" mb={1}>
            Welcome, guest!
          </Typography>
          <LoginButton />
        </Stack>
      )}

      {isAuthenticated && (
        <>
          <AuthGuard render={(user) => <NavigateRoleHomePage user={user} />} />
          {isLoggingIn && <Typography>Logging you in...</Typography>}
        </>
      )}
    </>
  );
};

import { Action, combineReducers, configureStore, Middleware, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { featureFlagData } from '~/features/feature-flags/featureFlagSlice';
import { authSlice } from '~/features/auth/authSlice';

import { snackbarSlice } from '~/features/snackbar/snackBarSlice';
import storage from 'redux-persist/lib/storage';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AxiosError } from 'axios';

export const emptySkillsMatrixData = createApi({
  tagTypes: ['SkillOverview', 'SkillRoster', 'SkillProfile'],
  reducerPath: 'skillsMatrixData',
  baseQuery: fakeBaseQuery<AxiosError | Error>(), // informs typescript what kind of errors our queryFn's might return
  endpoints: () => ({}),
});

declare module 'redux' {
  interface Store {
    /** record of injected reducers */
    asyncReducers: Record<string, any>;

    /** method to inject a reducer */
    injectReducer: (key: string, asyncReducer: any) => void;

    /* a mechanism to lazy inject middleware */
    injectMiddleware: (middleware: Middleware) => void;
  }
}

const staticReducers = {
  [authSlice.name]: authSlice.reducer,
  [snackbarSlice.name]: snackbarSlice.reducer,
  [featureFlagData.reducerPath]: featureFlagData.reducer,
  [emptySkillsMatrixData.reducerPath]: emptySkillsMatrixData.reducer,
};

const injectedMiddlewares: ReturnType<Middleware>[] = [];
const injectableMiddleware: Middleware<{}, any> = (_api) => (dispatch) => (action) => {
  injectedMiddlewares.forEach((middleware) => middleware(dispatch)(action));
  return dispatch(action);
};

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

// Configure the store
export default function configureInjectableStore() {
  const store = configureStore({
    reducer: persistReducer(rootPersistConfig, combineReducers(staticReducers)) as any,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {},
        },
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(injectableMiddleware, emptySkillsMatrixData.middleware, featureFlagData.middleware),
  });

  setupListeners(store.dispatch);

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(
      persistReducer(
        {
          key: 'root',
          whitelist: [],
          storage,
        },
        createReducer(store.asyncReducers) as any
      ) as any
    );
  };

  store.injectMiddleware = (middleware) => {
    injectedMiddlewares.push(middleware(store));
  };

  // Return the modified store
  return store;
}

function createReducer(asyncReducers: Record<string, any>) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

export const store = configureInjectableStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

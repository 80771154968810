/* tslint:disable */
/* eslint-disable */
/**
 * ATG.Intranet.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssignSkillRequest } from '../model';
// @ts-ignore
import { CompetencyResponse } from '../model';
// @ts-ignore
import { CreateCompetencyRequest } from '../model';
// @ts-ignore
import { CreateSkillRequest } from '../model';
// @ts-ignore
import { DisciplineResponse } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { ModelUpdateIdentifier } from '../model';
// @ts-ignore
import { ProfileResponse } from '../model';
// @ts-ignore
import { RosterResponse } from '../model';
// @ts-ignore
import { SkillLevelRefData } from '../model';
// @ts-ignore
import { SkillRefData } from '../model';
// @ts-ignore
import { SkillRosterResponse } from '../model';
// @ts-ignore
import { SkillUpdateBulkEntry } from '../model';
// @ts-ignore
import { SkillUpdateIdentifier } from '../model';
// @ts-ignore
import { SkillsOverviewResponse } from '../model';
// @ts-ignore
import { UpdateCompetencyChanges } from '../model';
// @ts-ignore
import { UpdateSkillAssignmentRequest } from '../model';
// @ts-ignore
import { UpdateSkillRequest } from '../model';
// @ts-ignore
import { ValidationErrorResponse } from '../model';
/**
 * SkillsMatrixApi - axios parameter creator
 * @export
 */
export const SkillsMatrixApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assigns a skill to a user
         * @param {string} userKey The key identifying the user to whom the skill should be assigned
         * @param {AssignSkillRequest} [assignSkillRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignSkill: async (userKey: string, assignSkillRequest?: AssignSkillRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userKey' is not null or undefined
            assertParamExists('assignSkill', 'userKey', userKey)
            const localVarPath = `/api/v1/skills-matrix/users/{userKey}/skills`
                .replace(`{${"userKey"}}`, encodeURIComponent(String(userKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignSkillRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new competency under a given discipline
         * @param {string} disciplineKey The key identifying the competency under which to create a competency
         * @param {CreateCompetencyRequest} [createCompetencyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompetency: async (disciplineKey: string, createCompetencyRequest?: CreateCompetencyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disciplineKey' is not null or undefined
            assertParamExists('createCompetency', 'disciplineKey', disciplineKey)
            const localVarPath = `/api/v1/skills-matrix/disciplines/{disciplineKey}/competencies`
                .replace(`{${"disciplineKey"}}`, encodeURIComponent(String(disciplineKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompetencyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new skill under a given discipline and competency
         * @param {string} disciplineKey The key identifying the discipline
         * @param {string} competencyKey The key identifying the competency
         * @param {CreateSkillRequest} [createSkillRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSkill: async (disciplineKey: string, competencyKey: string, createSkillRequest?: CreateSkillRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disciplineKey' is not null or undefined
            assertParamExists('createSkill', 'disciplineKey', disciplineKey)
            // verify required parameter 'competencyKey' is not null or undefined
            assertParamExists('createSkill', 'competencyKey', competencyKey)
            const localVarPath = `/api/v1/skills-matrix/disciplines/{disciplineKey}/competencies/{competencyKey}/skills`
                .replace(`{${"disciplineKey"}}`, encodeURIComponent(String(disciplineKey)))
                .replace(`{${"competencyKey"}}`, encodeURIComponent(String(competencyKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSkillRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a skill
         * @param {string} key The key identifying the skill to delete
         * @param {string} version The version number of the skill to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkill: async (key: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteSkill', 'key', key)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('deleteSkill', 'version', version)
            const localVarPath = `/api/v1/skills-matrix/skills/{key};{version}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Metadata: Gets the list of competencies for a given discipline
         * @param {string} disciplineKey The key identifying the discipline for which to load competencies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetencies: async (disciplineKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disciplineKey' is not null or undefined
            assertParamExists('getCompetencies', 'disciplineKey', disciplineKey)
            const localVarPath = `/api/v1/skills-matrix/disciplines/{disciplineKey}/competencies`
                .replace(`{${"disciplineKey"}}`, encodeURIComponent(String(disciplineKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Metadata: Gets the list of disciplines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisciplines: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/skills-matrix/disciplines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the skills details of a given user
         * @param {string} slug The slug identifying the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getProfile', 'slug', slug)
            const localVarPath = `/api/v1/skills-matrix/users/profiles/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of associates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoster: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/skills-matrix/roster`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Metadata: Gets the list of skill levels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillLevels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/skills-matrix/skill-levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of associates with a given skill
         * @param {string} slug The slug identifying the skill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillRoster: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getSkillRoster', 'slug', slug)
            const localVarPath = `/api/v1/skills-matrix/skill/{slug}/roster`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Metadata: Gets the list of skills
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkills: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/skills-matrix/skills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a report on the different skills and how many associates have that skill at each level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillsOverview: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/skills-matrix/skills/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a skill assignment from a user
         * @param {string} userKey The key identifying the user
         * @param {string} skillKey The key identifying the skill to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unAssignSkill: async (userKey: string, skillKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userKey' is not null or undefined
            assertParamExists('unAssignSkill', 'userKey', userKey)
            // verify required parameter 'skillKey' is not null or undefined
            assertParamExists('unAssignSkill', 'skillKey', skillKey)
            const localVarPath = `/api/v1/skills-matrix/users/{userKey}/skills/{skillKey}`
                .replace(`{${"userKey"}}`, encodeURIComponent(String(userKey)))
                .replace(`{${"skillKey"}}`, encodeURIComponent(String(skillKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a competency
         * @param {string} disciplineKey The key identifying the discipline to update
         * @param {string} competencyKey The key identifying the competency to update
         * @param {string} version The version of the competency to be updated
         * @param {UpdateCompetencyChanges} [updateCompetencyChanges] The data about the competency to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompetency: async (disciplineKey: string, competencyKey: string, version: string, updateCompetencyChanges?: UpdateCompetencyChanges, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disciplineKey' is not null or undefined
            assertParamExists('updateCompetency', 'disciplineKey', disciplineKey)
            // verify required parameter 'competencyKey' is not null or undefined
            assertParamExists('updateCompetency', 'competencyKey', competencyKey)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('updateCompetency', 'version', version)
            const localVarPath = `/api/v1/skills-matrix/disciplines/{disciplineKey}/competencies/{competencyKey};{version}`
                .replace(`{${"disciplineKey"}}`, encodeURIComponent(String(disciplineKey)))
                .replace(`{${"competencyKey"}}`, encodeURIComponent(String(competencyKey)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompetencyChanges, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a skill
         * @param {string} key The key identifying the skill to update
         * @param {string} version 
         * @param {UpdateSkillRequest} [updateSkillRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill: async (key: string, version: string, updateSkillRequest?: UpdateSkillRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateSkill', 'key', key)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('updateSkill', 'version', version)
            const localVarPath = `/api/v1/skills-matrix/skills/{key};{version}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSkillRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the details of a skill assignment for a given user
         * @param {string} userKey The key identifying the user
         * @param {string} skillKey The key identifying the skill to modify
         * @param {UpdateSkillAssignmentRequest} [updateSkillAssignmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkillAssignment: async (userKey: string, skillKey: string, updateSkillAssignmentRequest?: UpdateSkillAssignmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userKey' is not null or undefined
            assertParamExists('updateSkillAssignment', 'userKey', userKey)
            // verify required parameter 'skillKey' is not null or undefined
            assertParamExists('updateSkillAssignment', 'skillKey', skillKey)
            const localVarPath = `/api/v1/skills-matrix/users/{userKey}/skills/{skillKey}`
                .replace(`{${"userKey"}}`, encodeURIComponent(String(userKey)))
                .replace(`{${"skillKey"}}`, encodeURIComponent(String(skillKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSkillAssignmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk updates the skills for a given user
         * @param {string} userKey The key identifying the user
         * @param {Array<SkillUpdateBulkEntry>} [skillUpdateBulkEntry] Set of changes to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkillAssignmentsBulk: async (userKey: string, skillUpdateBulkEntry?: Array<SkillUpdateBulkEntry>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userKey' is not null or undefined
            assertParamExists('updateSkillAssignmentsBulk', 'userKey', userKey)
            const localVarPath = `/api/v1/skills-matrix/users/{userKey}/skills/bulk`
                .replace(`{${"userKey"}}`, encodeURIComponent(String(userKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(skillUpdateBulkEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SkillsMatrixApi - functional programming interface
 * @export
 */
export const SkillsMatrixApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SkillsMatrixApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assigns a skill to a user
         * @param {string} userKey The key identifying the user to whom the skill should be assigned
         * @param {AssignSkillRequest} [assignSkillRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignSkill(userKey: string, assignSkillRequest?: AssignSkillRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelUpdateIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignSkill(userKey, assignSkillRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new competency under a given discipline
         * @param {string} disciplineKey The key identifying the competency under which to create a competency
         * @param {CreateCompetencyRequest} [createCompetencyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompetency(disciplineKey: string, createCompetencyRequest?: CreateCompetencyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelUpdateIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompetency(disciplineKey, createCompetencyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new skill under a given discipline and competency
         * @param {string} disciplineKey The key identifying the discipline
         * @param {string} competencyKey The key identifying the competency
         * @param {CreateSkillRequest} [createSkillRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSkill(disciplineKey: string, competencyKey: string, createSkillRequest?: CreateSkillRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillUpdateIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSkill(disciplineKey, competencyKey, createSkillRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a skill
         * @param {string} key The key identifying the skill to delete
         * @param {string} version The version number of the skill to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSkill(key: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSkill(key, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Metadata: Gets the list of competencies for a given discipline
         * @param {string} disciplineKey The key identifying the discipline for which to load competencies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompetencies(disciplineKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompetencyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompetencies(disciplineKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Metadata: Gets the list of disciplines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDisciplines(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DisciplineResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDisciplines(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the skills details of a given user
         * @param {string} slug The slug identifying the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of associates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoster(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RosterResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoster(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Metadata: Gets the list of skill levels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillLevels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SkillLevelRefData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillLevels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the list of associates with a given skill
         * @param {string} slug The slug identifying the skill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillRoster(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillRosterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillRoster(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Metadata: Gets the list of skills
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkills(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SkillRefData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkills(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a report on the different skills and how many associates have that skill at each level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillsOverview(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SkillsOverviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillsOverview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes a skill assignment from a user
         * @param {string} userKey The key identifying the user
         * @param {string} skillKey The key identifying the skill to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unAssignSkill(userKey: string, skillKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unAssignSkill(userKey, skillKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a competency
         * @param {string} disciplineKey The key identifying the discipline to update
         * @param {string} competencyKey The key identifying the competency to update
         * @param {string} version The version of the competency to be updated
         * @param {UpdateCompetencyChanges} [updateCompetencyChanges] The data about the competency to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompetency(disciplineKey: string, competencyKey: string, version: string, updateCompetencyChanges?: UpdateCompetencyChanges, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelUpdateIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompetency(disciplineKey, competencyKey, version, updateCompetencyChanges, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a skill
         * @param {string} key The key identifying the skill to update
         * @param {string} version 
         * @param {UpdateSkillRequest} [updateSkillRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSkill(key: string, version: string, updateSkillRequest?: UpdateSkillRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillUpdateIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSkill(key, version, updateSkillRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the details of a skill assignment for a given user
         * @param {string} userKey The key identifying the user
         * @param {string} skillKey The key identifying the skill to modify
         * @param {UpdateSkillAssignmentRequest} [updateSkillAssignmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSkillAssignment(userKey: string, skillKey: string, updateSkillAssignmentRequest?: UpdateSkillAssignmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelUpdateIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSkillAssignment(userKey, skillKey, updateSkillAssignmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bulk updates the skills for a given user
         * @param {string} userKey The key identifying the user
         * @param {Array<SkillUpdateBulkEntry>} [skillUpdateBulkEntry] Set of changes to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSkillAssignmentsBulk(userKey: string, skillUpdateBulkEntry?: Array<SkillUpdateBulkEntry>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelUpdateIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSkillAssignmentsBulk(userKey, skillUpdateBulkEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SkillsMatrixApi - factory interface
 * @export
 */
export const SkillsMatrixApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SkillsMatrixApiFp(configuration)
    return {
        /**
         * 
         * @summary Assigns a skill to a user
         * @param {string} userKey The key identifying the user to whom the skill should be assigned
         * @param {AssignSkillRequest} [assignSkillRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignSkill(userKey: string, assignSkillRequest?: AssignSkillRequest, options?: any): AxiosPromise<ModelUpdateIdentifier> {
            return localVarFp.assignSkill(userKey, assignSkillRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new competency under a given discipline
         * @param {string} disciplineKey The key identifying the competency under which to create a competency
         * @param {CreateCompetencyRequest} [createCompetencyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompetency(disciplineKey: string, createCompetencyRequest?: CreateCompetencyRequest, options?: any): AxiosPromise<ModelUpdateIdentifier> {
            return localVarFp.createCompetency(disciplineKey, createCompetencyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new skill under a given discipline and competency
         * @param {string} disciplineKey The key identifying the discipline
         * @param {string} competencyKey The key identifying the competency
         * @param {CreateSkillRequest} [createSkillRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSkill(disciplineKey: string, competencyKey: string, createSkillRequest?: CreateSkillRequest, options?: any): AxiosPromise<SkillUpdateIdentifier> {
            return localVarFp.createSkill(disciplineKey, competencyKey, createSkillRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a skill
         * @param {string} key The key identifying the skill to delete
         * @param {string} version The version number of the skill to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkill(key: string, version: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteSkill(key, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Metadata: Gets the list of competencies for a given discipline
         * @param {string} disciplineKey The key identifying the discipline for which to load competencies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetencies(disciplineKey: string, options?: any): AxiosPromise<Array<CompetencyResponse>> {
            return localVarFp.getCompetencies(disciplineKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Metadata: Gets the list of disciplines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisciplines(options?: any): AxiosPromise<Array<DisciplineResponse>> {
            return localVarFp.getDisciplines(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the skills details of a given user
         * @param {string} slug The slug identifying the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(slug: string, options?: any): AxiosPromise<ProfileResponse> {
            return localVarFp.getProfile(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of associates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoster(options?: any): AxiosPromise<Array<RosterResponse>> {
            return localVarFp.getRoster(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Metadata: Gets the list of skill levels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillLevels(options?: any): AxiosPromise<Array<SkillLevelRefData>> {
            return localVarFp.getSkillLevels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of associates with a given skill
         * @param {string} slug The slug identifying the skill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillRoster(slug: string, options?: any): AxiosPromise<SkillRosterResponse> {
            return localVarFp.getSkillRoster(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Metadata: Gets the list of skills
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkills(options?: any): AxiosPromise<Array<SkillRefData>> {
            return localVarFp.getSkills(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a report on the different skills and how many associates have that skill at each level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillsOverview(options?: any): AxiosPromise<Array<SkillsOverviewResponse>> {
            return localVarFp.getSkillsOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a skill assignment from a user
         * @param {string} userKey The key identifying the user
         * @param {string} skillKey The key identifying the skill to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unAssignSkill(userKey: string, skillKey: string, options?: any): AxiosPromise<object> {
            return localVarFp.unAssignSkill(userKey, skillKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a competency
         * @param {string} disciplineKey The key identifying the discipline to update
         * @param {string} competencyKey The key identifying the competency to update
         * @param {string} version The version of the competency to be updated
         * @param {UpdateCompetencyChanges} [updateCompetencyChanges] The data about the competency to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompetency(disciplineKey: string, competencyKey: string, version: string, updateCompetencyChanges?: UpdateCompetencyChanges, options?: any): AxiosPromise<ModelUpdateIdentifier> {
            return localVarFp.updateCompetency(disciplineKey, competencyKey, version, updateCompetencyChanges, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a skill
         * @param {string} key The key identifying the skill to update
         * @param {string} version 
         * @param {UpdateSkillRequest} [updateSkillRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill(key: string, version: string, updateSkillRequest?: UpdateSkillRequest, options?: any): AxiosPromise<SkillUpdateIdentifier> {
            return localVarFp.updateSkill(key, version, updateSkillRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the details of a skill assignment for a given user
         * @param {string} userKey The key identifying the user
         * @param {string} skillKey The key identifying the skill to modify
         * @param {UpdateSkillAssignmentRequest} [updateSkillAssignmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkillAssignment(userKey: string, skillKey: string, updateSkillAssignmentRequest?: UpdateSkillAssignmentRequest, options?: any): AxiosPromise<ModelUpdateIdentifier> {
            return localVarFp.updateSkillAssignment(userKey, skillKey, updateSkillAssignmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bulk updates the skills for a given user
         * @param {string} userKey The key identifying the user
         * @param {Array<SkillUpdateBulkEntry>} [skillUpdateBulkEntry] Set of changes to apply
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkillAssignmentsBulk(userKey: string, skillUpdateBulkEntry?: Array<SkillUpdateBulkEntry>, options?: any): AxiosPromise<ModelUpdateIdentifier> {
            return localVarFp.updateSkillAssignmentsBulk(userKey, skillUpdateBulkEntry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignSkill operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiAssignSkillRequest
 */
export interface SkillsMatrixApiAssignSkillRequest {
    /**
     * The key identifying the user to whom the skill should be assigned
     * @type {string}
     * @memberof SkillsMatrixApiAssignSkill
     */
    readonly userKey: string

    /**
     * 
     * @type {AssignSkillRequest}
     * @memberof SkillsMatrixApiAssignSkill
     */
    readonly assignSkillRequest?: AssignSkillRequest
}

/**
 * Request parameters for createCompetency operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiCreateCompetencyRequest
 */
export interface SkillsMatrixApiCreateCompetencyRequest {
    /**
     * The key identifying the competency under which to create a competency
     * @type {string}
     * @memberof SkillsMatrixApiCreateCompetency
     */
    readonly disciplineKey: string

    /**
     * 
     * @type {CreateCompetencyRequest}
     * @memberof SkillsMatrixApiCreateCompetency
     */
    readonly createCompetencyRequest?: CreateCompetencyRequest
}

/**
 * Request parameters for createSkill operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiCreateSkillRequest
 */
export interface SkillsMatrixApiCreateSkillRequest {
    /**
     * The key identifying the discipline
     * @type {string}
     * @memberof SkillsMatrixApiCreateSkill
     */
    readonly disciplineKey: string

    /**
     * The key identifying the competency
     * @type {string}
     * @memberof SkillsMatrixApiCreateSkill
     */
    readonly competencyKey: string

    /**
     * 
     * @type {CreateSkillRequest}
     * @memberof SkillsMatrixApiCreateSkill
     */
    readonly createSkillRequest?: CreateSkillRequest
}

/**
 * Request parameters for deleteSkill operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiDeleteSkillRequest
 */
export interface SkillsMatrixApiDeleteSkillRequest {
    /**
     * The key identifying the skill to delete
     * @type {string}
     * @memberof SkillsMatrixApiDeleteSkill
     */
    readonly key: string

    /**
     * The version number of the skill to delete
     * @type {string}
     * @memberof SkillsMatrixApiDeleteSkill
     */
    readonly version: string
}

/**
 * Request parameters for getCompetencies operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiGetCompetenciesRequest
 */
export interface SkillsMatrixApiGetCompetenciesRequest {
    /**
     * The key identifying the discipline for which to load competencies
     * @type {string}
     * @memberof SkillsMatrixApiGetCompetencies
     */
    readonly disciplineKey: string
}

/**
 * Request parameters for getProfile operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiGetProfileRequest
 */
export interface SkillsMatrixApiGetProfileRequest {
    /**
     * The slug identifying the user
     * @type {string}
     * @memberof SkillsMatrixApiGetProfile
     */
    readonly slug: string
}

/**
 * Request parameters for getSkillRoster operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiGetSkillRosterRequest
 */
export interface SkillsMatrixApiGetSkillRosterRequest {
    /**
     * The slug identifying the skill
     * @type {string}
     * @memberof SkillsMatrixApiGetSkillRoster
     */
    readonly slug: string
}

/**
 * Request parameters for unAssignSkill operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiUnAssignSkillRequest
 */
export interface SkillsMatrixApiUnAssignSkillRequest {
    /**
     * The key identifying the user
     * @type {string}
     * @memberof SkillsMatrixApiUnAssignSkill
     */
    readonly userKey: string

    /**
     * The key identifying the skill to remove
     * @type {string}
     * @memberof SkillsMatrixApiUnAssignSkill
     */
    readonly skillKey: string
}

/**
 * Request parameters for updateCompetency operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiUpdateCompetencyRequest
 */
export interface SkillsMatrixApiUpdateCompetencyRequest {
    /**
     * The key identifying the discipline to update
     * @type {string}
     * @memberof SkillsMatrixApiUpdateCompetency
     */
    readonly disciplineKey: string

    /**
     * The key identifying the competency to update
     * @type {string}
     * @memberof SkillsMatrixApiUpdateCompetency
     */
    readonly competencyKey: string

    /**
     * The version of the competency to be updated
     * @type {string}
     * @memberof SkillsMatrixApiUpdateCompetency
     */
    readonly version: string

    /**
     * The data about the competency to be updated
     * @type {UpdateCompetencyChanges}
     * @memberof SkillsMatrixApiUpdateCompetency
     */
    readonly updateCompetencyChanges?: UpdateCompetencyChanges
}

/**
 * Request parameters for updateSkill operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiUpdateSkillRequest
 */
export interface SkillsMatrixApiUpdateSkillRequest {
    /**
     * The key identifying the skill to update
     * @type {string}
     * @memberof SkillsMatrixApiUpdateSkill
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof SkillsMatrixApiUpdateSkill
     */
    readonly version: string

    /**
     * 
     * @type {UpdateSkillRequest}
     * @memberof SkillsMatrixApiUpdateSkill
     */
    readonly updateSkillRequest?: UpdateSkillRequest
}

/**
 * Request parameters for updateSkillAssignment operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiUpdateSkillAssignmentRequest
 */
export interface SkillsMatrixApiUpdateSkillAssignmentRequest {
    /**
     * The key identifying the user
     * @type {string}
     * @memberof SkillsMatrixApiUpdateSkillAssignment
     */
    readonly userKey: string

    /**
     * The key identifying the skill to modify
     * @type {string}
     * @memberof SkillsMatrixApiUpdateSkillAssignment
     */
    readonly skillKey: string

    /**
     * 
     * @type {UpdateSkillAssignmentRequest}
     * @memberof SkillsMatrixApiUpdateSkillAssignment
     */
    readonly updateSkillAssignmentRequest?: UpdateSkillAssignmentRequest
}

/**
 * Request parameters for updateSkillAssignmentsBulk operation in SkillsMatrixApi.
 * @export
 * @interface SkillsMatrixApiUpdateSkillAssignmentsBulkRequest
 */
export interface SkillsMatrixApiUpdateSkillAssignmentsBulkRequest {
    /**
     * The key identifying the user
     * @type {string}
     * @memberof SkillsMatrixApiUpdateSkillAssignmentsBulk
     */
    readonly userKey: string

    /**
     * Set of changes to apply
     * @type {Array<SkillUpdateBulkEntry>}
     * @memberof SkillsMatrixApiUpdateSkillAssignmentsBulk
     */
    readonly skillUpdateBulkEntry?: Array<SkillUpdateBulkEntry>
}

/**
 * SkillsMatrixApi - object-oriented interface
 * @export
 * @class SkillsMatrixApi
 * @extends {BaseAPI}
 */
export class SkillsMatrixApi extends BaseAPI {
    /**
     * 
     * @summary Assigns a skill to a user
     * @param {SkillsMatrixApiAssignSkillRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public assignSkill(requestParameters: SkillsMatrixApiAssignSkillRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).assignSkill(requestParameters.userKey, requestParameters.assignSkillRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new competency under a given discipline
     * @param {SkillsMatrixApiCreateCompetencyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public createCompetency(requestParameters: SkillsMatrixApiCreateCompetencyRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).createCompetency(requestParameters.disciplineKey, requestParameters.createCompetencyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new skill under a given discipline and competency
     * @param {SkillsMatrixApiCreateSkillRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public createSkill(requestParameters: SkillsMatrixApiCreateSkillRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).createSkill(requestParameters.disciplineKey, requestParameters.competencyKey, requestParameters.createSkillRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a skill
     * @param {SkillsMatrixApiDeleteSkillRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public deleteSkill(requestParameters: SkillsMatrixApiDeleteSkillRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).deleteSkill(requestParameters.key, requestParameters.version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Metadata: Gets the list of competencies for a given discipline
     * @param {SkillsMatrixApiGetCompetenciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public getCompetencies(requestParameters: SkillsMatrixApiGetCompetenciesRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).getCompetencies(requestParameters.disciplineKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Metadata: Gets the list of disciplines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public getDisciplines(options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).getDisciplines(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the skills details of a given user
     * @param {SkillsMatrixApiGetProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public getProfile(requestParameters: SkillsMatrixApiGetProfileRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).getProfile(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of associates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public getRoster(options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).getRoster(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Metadata: Gets the list of skill levels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public getSkillLevels(options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).getSkillLevels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of associates with a given skill
     * @param {SkillsMatrixApiGetSkillRosterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public getSkillRoster(requestParameters: SkillsMatrixApiGetSkillRosterRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).getSkillRoster(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Metadata: Gets the list of skills
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public getSkills(options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).getSkills(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a report on the different skills and how many associates have that skill at each level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public getSkillsOverview(options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).getSkillsOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a skill assignment from a user
     * @param {SkillsMatrixApiUnAssignSkillRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public unAssignSkill(requestParameters: SkillsMatrixApiUnAssignSkillRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).unAssignSkill(requestParameters.userKey, requestParameters.skillKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a competency
     * @param {SkillsMatrixApiUpdateCompetencyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public updateCompetency(requestParameters: SkillsMatrixApiUpdateCompetencyRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).updateCompetency(requestParameters.disciplineKey, requestParameters.competencyKey, requestParameters.version, requestParameters.updateCompetencyChanges, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a skill
     * @param {SkillsMatrixApiUpdateSkillRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public updateSkill(requestParameters: SkillsMatrixApiUpdateSkillRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).updateSkill(requestParameters.key, requestParameters.version, requestParameters.updateSkillRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the details of a skill assignment for a given user
     * @param {SkillsMatrixApiUpdateSkillAssignmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public updateSkillAssignment(requestParameters: SkillsMatrixApiUpdateSkillAssignmentRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).updateSkillAssignment(requestParameters.userKey, requestParameters.skillKey, requestParameters.updateSkillAssignmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bulk updates the skills for a given user
     * @param {SkillsMatrixApiUpdateSkillAssignmentsBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillsMatrixApi
     */
    public updateSkillAssignmentsBulk(requestParameters: SkillsMatrixApiUpdateSkillAssignmentsBulkRequest, options?: AxiosRequestConfig) {
        return SkillsMatrixApiFp(this.configuration).updateSkillAssignmentsBulk(requestParameters.userKey, requestParameters.skillUpdateBulkEntry, options).then((request) => request(this.axios, this.basePath));
    }
}

import { PublicClientApplication } from '@azure/msal-browser';
import { Config } from '~/app/config';

// https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
export const msalConfig = {
  auth: {
    clientId: Config.oauth.clientId!,
    authority: Config.oauth.authority!,
    redirectUri: Config.oauth.redirectUri!,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [Config.oauth.intranetAccessScope!, 'User.Read'],
  extraQueryParameters: {
    domain_hint: 'artisantechgroup.com',
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

import { LinearProgress } from '@mui/material';
import { LoginScreen } from '~/features/auth/LoginScreen';
import { NotFoundPage } from '~/NotFoundPage';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { HomePage } from './features/home/HomePage';
import { MainLayout } from './MainLayout';

// lazy load all the code for skills matrix UI
const SkillsMatrixRoutes = React.lazy(() => import('./features/skills-matrix/skills-matrix-routes'));
const GuesstimatorRoutes = React.lazy(() => import('./features/guesstimator/guesstimator-routes'));

// typesafe route generator functions in paths.ts
// but it doesnt play nice with nested route declarations and thus you'll have to keep them in sync
function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route path="login" element={<LoginScreen />} />
        <Route
          path="sm/*"
          element={
            <Suspense fallback={<SuspenseFallback />}>
              <SkillsMatrixRoutes />
            </Suspense>
          }
        />
        <Route
          path="guesstimator/*"
          element={
            <Suspense fallback={<SuspenseFallback />}>
              <GuesstimatorRoutes />
            </Suspense>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

function SuspenseFallback() {
  return <LinearProgress color="inherit" />;
}

export default App;

import axios from 'axios';
import { Config } from '~/app/config';
import { AuthApi, Configuration, SkillsMatrixApi } from '~/app/generated/api-client/v1';
import { loginRequest, msalInstance } from '~/features/auth/authConfig';
import { ConfigApi } from '~/app/generated/api-client/v1';

axios.interceptors.request.use(async (config) => {
  if (msalInstance.getActiveAccount()) {
    // if we're logged in, we should try to include the bearer token on all api requests
    const accessTokenRequest = {
      ...loginRequest,
      account: msalInstance.getActiveAccount()!,
    };
    const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest).catch((_e) => {
      return msalInstance.acquireTokenPopup(accessTokenRequest);
    });
    config.headers.Authorization = `Bearer ${accessTokenResponse.accessToken}`;
  }

  return config;
});

export const config = new Configuration({ basePath: Config.apiRoot });

export const Api = {
  skillsMatrix: new SkillsMatrixApi(config),
  auth: new AuthApi(config),
  config: new ConfigApi(config),
};
